import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

type PanelName = 'history' | 'nodeSetting' | 'setTrigger' | ''

interface IStore {
  actionPanelName: PanelName
  setPanel: (name: PanelName) => void
}

const layout = create<IStore>()(
  devtools(set => ({
    actionPanelName: '',
    setPanel: (name: PanelName) => set({ actionPanelName: name })
  }))
)

export default layout
